@import url(https://fonts.googleapis.com/css?family=Yantramanav:400,500,700,900);
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  // background: #181818;
  // color: white;
}
.App {
  text-align: center;
}

.firecalc {
  &__title {
    font-size: 2.5rem;
    // color: #fff;
    font-weight: 900;
    margin: 0;
    line-height: 1em;
    font-family: 'yantramanav';
    text-transform: uppercase;
    text-align: center;

    &::after {
      content: '';
      width: 10%;
      position: relative;
      border-bottom: 1px solid #ef5a00;
      display: block;
      text-align: center;
      margin: auto;
      margin-top: 25px;
    }
  }

  &__form-container {
    max-width: 600px;
    margin: auto;
  }

  &__subtitle {
    // color: #111;
    font-family: 'Open Sans', sans-serif;
    font-size: 4vw;
    font-weight: 300;
    line-height: 32px;
    margin: 0 0 72px;
    text-align: center;
  }

  &__title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // background: #181818;
    margin: 0;
    padding: 60px 0 30px;
    max-width: 600px;
    margin: auto;
  }

  &__highlighted {
    color: #ef5a00;
  }

  &__label {
    padding: 10px;
    font-size: 0.5rem;
  }

  &__section-title {
    font-size: 1.3rem;
    // color: #fff;
    font-family: 'yantramanav';
    font-weight: 900;
    margin: auto;
    line-height: 1em;
    font-family: 'yantramanav';
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    text-decoration: underline#ef5a00;
  }

  &__radio-container {
    justify-content: center;
    padding: 10px;
    align-items: center;
  }

  &__radio-group {
    justify-content: center;
    align-items: center;
  }

  &__label {
    margin: auto;
    span {
      text-align: center;
      font-size: 0.7rem;
      // color: #fff;
      font-weight: 900;
      margin: 0;
      line-height: 1em;
      font-family: 'yantramanav';
      text-transform: uppercase;
      text-align: center;
    }
  }

  &__chart-text {
    text-align: center;
    font-size: 0.9rem;
    // color: #fff;
    font-weight: 400;
    margin: 0;
    line-height: 1.2em;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  &__button-container button {
    margin-inline: 5px;
  }

  &__button {
    // color: pink;
    font-size: 20px;
    font-weight: 900;
    // background-color: #111;
  }

  &__results-header {
    display: flex;
    justify-content: center;
  }

  &__popover {
    color: white;
    font-size: 10px;
    font-weight: 600;
    padding: 0px 20px 0px 20px;
  }
}

.MuiButton {
  &-label {
    padding: 2px;
    font-size: 1.2rem;
    // color: #fff;
    font-weight: 900;
    margin: 0;
    line-height: 1em;
    font-family: 'yantramanav';
    text-transform: uppercase;
    text-align: center;
    // color: #fff;
  }

  &-contained {
    // background: #181818 !important;
    border: 1px solid #ef5a00 !important;
  }
}

.MuiFormControl {
  &-root {
    label.MuiFormLabel-root {
      font-size: 0.8rem !important;
      padding: 2px;
      font-size: 1.2rem;
      // color: #fff;
      font-weight: 900;
      margin: 0;
      line-height: 1em;
      font-family: 'yantramanav';
      text-transform: uppercase;
      text-align: center;
    }
  }
}

.MuiFormControl-root.MuiTextField-root {
  border-radius: 3px;
  // background: #302d2d;
}

.apexcharts-tooltip-title {
  background-color: #808080 !important;
  font-weight: 900;
}

label {
  margin: auto !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
